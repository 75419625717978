<template>
  <v-row justify="center">
    <v-dialog v-model="show" max-width="450" persistent>
        <v-card class="pa-8">
          <v-card-text class="text-center pa-0 pb-6 text-h6 font-weight-regular">
            เซสชั่นหมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง <br>
            Session Expired, Please login again.
          </v-card-text>
          <v-card-actions class="pa-0 justify-center">
            <v-btn outlined @click="$emit('close')" color="red">Close</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

export default {
  data: function() {
    return {      
    };
  },
  computed: {
  },
  props: ["show"],
  methods: {   
  },
};
</script>
<style></style>
