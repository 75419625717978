<template>
  <div id="sildeOTP">
    <input 
      type="range" 
      class="slideToUnlock" 
      id="unlock"
      min="0" 
      max="100"
      :disabled="disabled" 
      v-model="sliderValue"
      @input="update"
      @change="change"
      @mouseup="resetDrag" 
      @touchend="resetDrag" 
    />
    <div class="dv_text">{{message}}</div>
  </div>
</template>
<script>
export default {
  name: "dragVerify",
  props: {
    text: {
      type: String,
      default: 'swiping to the right side',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      sliderValue: "0",
    };
  },
  watch: {
    sliderValue(val) {
      if (val == 100) {
        this.$emit("successFunction")
      }
    },
    value: {
      immediate: true, 
      handler () {
        this.changeValues();
      }
    }
  },
  computed: {
    message(){
			return this.text
		},
  },
  mounted() {
    this.changeValues()
  },
  methods: {
    resetDrag() {
      // if (this.sliderValue < 100) {
        this.sliderValue = "0"
        this.$emit("input", this.sliderValue);
      // }
    },
    update() {
      this.$emit("input", this.sliderValue);
    },
    change() {
      this.$emit("change", this.sliderValue);
    },
    changeValues() {
      this.sliderValue = this.value;
    }
  },
};
</script>
<style scoped>
input[type="range"] {
  margin-top: 20px;
  background: linear-gradient(#4DADBB, #74C9AA);
  -webkit-appearance: none;
  border-radius: 50px;
  padding: 10px;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  outline: none;
}
input[type="range"]:before {
  /* content: "เลื่อนไปทางขวาเพื่อรับ OTP"; */
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 75px;
  top: 10px;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 50px;
  background: -webkit-linear-gradient(top, #fafafa 0%, #dedede 50%, #cfcfcf 51%, #a3a3a3 100%);
  position: relative;
  width: 50px;
  height: 30px;
  cursor: move;
  z-index: 2;
}
.slideToUnlock[disabled] {
  opacity: 0.65;
  cursor: not-allowed;
}
.dv_text {
  margin-top: -35px;
  position: sticky;
  z-index: 1;
  color: white;
}

/* mobile */
@media only screen and (max-width: 500px) {
  input[type="range"]:before {
    top: 8px;
  }
  .dv_text {
    margin-top: -33px;
    padding-left: 35px;
  }
}
</style>